<template>
  <section>
    <div class="content-header">
      <h2>Muslim identity and islamophobia</h2>
    </div>
    <div class="content-wrapper">
      <p>This section on Muslim identity is an opportunity to share information so that current and future Muslim students feel more comfortable expressing their Muslim identity and practising their religion. All information about the practices of Islam was discussed with a local Imam within the London community who approved of the information. </p>
      <p>As you read this section, it’s important to keep in mind that there exists a spectrum of Muslimhood and each person may have a varying level of what they choose to follow and the extent to which they practise their faith. </p>
      <p>There is also great diversity within Islam, and not all Muslims choose to practice their faith the same way. For example, the majority of the world's more than 1.5 billion Muslims are Sunni Muslims—estimates suggest the figure is somewhere between 85% and 90%. Further, Shias Muslims constitute about 10% of all Muslims, and globally their population is estimated at between 154 and 200 million. This broad division, still, hides many other more subtle differences. </p>
      <p>Within the university environment, we have narrowed down five key topics that pertain to Islam in day-to-day life. These topics may still apply differently to individual Muslims, so we must be careful to not generalize as we engage with individuals of Islamic faith in our community. </p>
      <p>The key topics include: </p>
      <ol>
        <li>Praying</li>
        <li>Drinking and Halal Foods </li>
        <li>Hijab</li>
        <li>Physical Contact</li>
        <li>Ramadan </li>
      </ol>
      <p>These are items that play a role in the average practicing Muslim’s day to day life, which means they are also salient in our identity as HBA students. The five aforementioned items may not be known or easily understood by our peers, which is why we hoped to share more information on our practices and how they affect the ways in which we navigate our student experiences. </p>
      <div class="accordion">
        <accordion
          headerText="Prayer "
          accordionID="collapse01"
        >
          <p>Islam has five pillars which are representative of its core practices and beliefs within the religion. The five pillars are as follows: profession of faith, prayer, charity, fasting and pilgrimage (if able to do so). As you can see, prayer makes up one of the five pillars, and therefore it’s an essential part to the average practicing Muslim’s identity. </p>
          <p>Muslims pray ritualistically five times a day, at specified times, with special movements and supplications. For example, the ritual morning prayer starts at dawn and ends at sunrise. The Muslim person will face the direction of Mecca during the prayer. They will perform special movements like bowing, prostrating and sitting. </p>
          <p>Prayer times change throughout the year according to the position of the sun. The first prayer time is at the beginning of twilight, when the morning sky can be seen across the width of the sky, and it ends at sunrise. The second prayer starts when the sun passes the highest point in the sky (its zenith) and lasts until the next prayer. The third prayer is based on the sun, when an object will have the same length as its shadow and this prayer lasts till the next prayer. The fourth prayer starts at sunset and goes until the next prayer. The last prayer starts when there is no light left in the sky and this prayer lasts until the first prayer for the next day. It’s important to understand that these prayers have time-spans rather than an exact time. Since they are dependent on the sun, they will change throughout the year, for example time spans are often shorter in the winter with approximately two hours between prayers. </p>
          <p>Muslims who pray five times a day will often need to take breaks throughout the day (including during exams, and 48s) to perform their acts of devotion. Typically, they’ll need between 8-10 minutes to complete their prayers and return to their task. If you have a group member who’s Muslim, you can consider demonstrating some understanding of Muslim prayer rituals to the extent that Muslim students do not have to explain themselves at length.</p>
          <p class="pb-2">An Ivey Honours Business Administration (HBA) student recounts why he often resorted to telling his peers that he was going to the bathroom rather than going to pray: </p>
          <div class="panel">
            <p class=""><em>“Sometimes it’s just easier to make an excuse without having to try and explain exactly why I must pray, and explain how I have to pray 5 times a day. It’s just mentally exhausting to have to remind people why these religious values mean so much to me. Most people are very respectful, but that doesn’t make it any less mentally draining. I’d rather just use the bathroom excuse, and just avoid the situation where people keep asking about my faith. I know it’s a good learning opportunity to educate people on faith, but when you’re swamped with casework, networking, recruiting, and just life, it’s just easier to make simple excuses and move on.” </em></p>
          </div>
          <ul>
            <li class="pt-3">If you see an individual bowing or prostrating in a corner, please know that they cannot speak during prayer, so please wait for them to finish. </li>
            <li class="pb-2">Muslim women are exempt from praying when menstruating. If you typically know a Muslim peer to regularly pray and notice them not praying, consider that this may be the reason, and refrain from questioning them. </li>
            <li class="pb-2">Many Muslim students are happy to answer questions about prayer, but do not appreciate people questioning their beliefs and challenging them on why they’re doing certain things. </li>
            <li class="pb-2">If you see a Muslim student praying, do not walk directly in front of them. The direction (towards Mecca) to which Muslims pray symbolizes the house of God (Ka’bah); so, walking in front of them would symbolize worshipping something other than God which is contrary to the belief of monotheism. </li>
            <li>If you see a Muslim student washing their feet (either in the sink, or with their shoes off), please note that they are performing ablution (cleansing ritual), which is a prerequisite to prayer; please do not judge or question them. </li>
          </ul>
        </accordion>
        <accordion
          headerText="Drinking alcohol and halal foods "
          accordionID="collapse02"
        >
          <p>In Islam, there are a set of guidelines that are in place for Muslims when it comes to things they can consume and what activities they can partake in. There are two main classifications for this: <em>Halal (permissible)</em> and <em>Haram (forbidden)</em>. In terms of foods, alcohol and pork are haram (forbidden) for Muslims to consume. In addition, any form of recreational drugs are also haram for Muslims to consume. </p>
          <p class="pb-2">In post-secondary institutions, a drinking culture acts as a barrier preventing many Muslim students from engaging with peers in social events. As many social events have alcohol present and majority of students drinking, it’s common for Muslim students to feel uncomfortable when attending such outings. Some may experience peer pressure to consume alcohol or feel left out because only ‘drinking games’ are played. Additionally, some members of the Muslim community follow an Islamic ruling that encourages them to refrain—to the best of their abilities—from attending spaces where alcohol is being served. Although efforts are made to establish non-alcoholic social gatherings as mandatory, generally students aren’t as enthused or willing to attend them. This causes many Muslim students to be socially excluded and prevents them from integrating with the rest of their peers. </p>
          <ul>
            <li class="pb-2">Social gatherings that centre on drinking can be exclusionary to Muslim students who choose not to drink. Please keep this in mind when planning social events and also consider outings that do not include alcohol. </li>
            <li class="pb-2">Even if a Muslim student is at a networking event or gathering where alcohol is being served, respect them by not having alcohol near their proximity (i.e., finish your drink and then approach them).</li>
            <li>If you’re part of a group having lunch/dinner together, it’s best practice to avoid alcohol in that setting because the Muslim student has no choice but to be in that environment. If the group intends to involve alcohol, give the student the option to leave. </li>
          </ul>
        </accordion>
        <accordion
          headerText="Women and hijab "
          accordionID="collapse03"
        >
          <p>In Islam many women may choose to wear the hijab, a fabric that is wrapped around a woman’s head ultimately covering their hair. Hijab is an expression of modesty. In the mainstream media, hijab wearing has been discussed and debated as a form of oppression. An HBA student described how wearing the hijab has the opposite effect: </p>
          <div class="panel">
            <p><em>“Personally to me, the hijab is a symbol of devotion and empowerment. The hijab is visible and shows my commitment to my faith and God. My hijab empowers me by standing against the hypersexualization of women in society. It allows me to take ownership of my own body and lets others judge me for who I am and not just how I look." </em></p>
          </div>
          <p class="pt-2">Western’s Anti-Racism Working Group (ARWG) observed that female Muslim students, in particular, are commonly subjected to sexism, harassment and racial microaggressions on campus. Examples included one student overhearing disrespectful comments (e.g., “My parents would kill me if I brought home a Muslim girl,” and “Muslim girls are the freakiest”). </p>
          <p>As some women may be visibly recognizable/perceived as Muslim (due to their hijab, burka, niqab, etc.), they disproportionately experience Islamophobia and anti-Muslim hate. Visibly Muslim women are often stereotyped as submissive and docile, or in need of liberation. </p>
          <p>Consequently, this perception has led visibly Muslim women to be targeted by various legislation (e.g., Bill 21 in Quebec), as some believe that those who wear a veil are oppressed and are forced to do so; therefore requiring them to remove their veil should be empowering. However, laws that withhold the right for Muslim women to choose what they want to wear is oppressive and anti-feminist, as it involves applying one’s own “Western ideals” of what a liberated woman should look like. </p>
        </accordion>
        <accordion
          headerText="Ramadan "
          accordionID="collapse04"
        >
          <p>Ramadan is the ninth month of the Islamic lunar calendar. The month of Ramadan is a sacred month where Muslims fast from the break of dawn to sunset. During this month, Muslims also take part in many different acts of worship from fasting, praying, giving to charity, etc. </p>
          <p class="pb-3">During Ramadan, Muslim students will likely be studying and writing their final exams while fasting for approximately 17 hours. Being mindful of this and having empathy suggests a community of care. </p>
          <ul>
            <li class="pb-2">In general, you should not question/call out a Muslim student if you notice that they’re not fasting; for example, women who are menstruating, are pregnant or lactating do not fast, so questioning them may make an interaction quite awkward. Others who may have medical conditions that require them to take prescription medication may not fast. It’s possible that that inquiring about a person's decision not to fast may put them in an uncomfortable situation with respect to disclosing personal or private information. </li>
            <li class="pb-2">If you’re curious about why Muslims fast or the rituals they perform, do keep in mind that Muslims get asked questions about fasting quite frequently, so instead of asking them repetitive questions, consider doing some high-level research to broaden your understanding.</li>
            <li class="pb-2">Keep in mind that Muslim students haven’t eaten/drunk for long periods of time (sometimes up to 18-19 hours); they will be physically/mentally exhausted and seeking to preserve their energy. </li>
          </ul>
        </accordion>
        <accordion
          headerText="Physical contact "
          accordionID="collapse05"
        >
          <p>In Islam, modesty is often emphasized in every aspect of life, whether it’s through physical covering or behaviour. As a result, physical touch with the opposite gender is typically discouraged within the Muslim community. Some Muslims may reject handshakes, hugs, high fives, and other forms of casual touch with the opposite gender to maintain their modesty. This does not mean that they are unappreciative of the interaction and dislike you, they are merely following religious obligations.</p>
        </accordion>
        <accordion
          headerText="What can I do to be an ally? "
          accordionID="collapse06"
        >
          <ul>
            <li class="pb-2">Ask the Muslim student privately how observant they are in terms of certain religious rules. From a religious perspective, physical touch with the opposite gender may not be allowed with strangers (family exceptions are different of course); however, a large portion of Muslim students may not adhere to this—as such, it’s best practice to privately ask them what they are okay with and respect their choice. </li>
            <li class="pb-2">If someone puts their hand over their heart, this would be an indication that they want to be respectful and are greeting you but do not want to physically shake your hand. </li>
            <li>Be mindful of an individual’s physical boundaries. Such mindfulness extends into many areas of equity, diversity and inclusion, including past trauma or public health concerns an individual may have. Make it a habit to ask all individuals (visibly Muslim or not), if you can shake their hands or give them a high five. </li>
          </ul>
        </accordion>
        <accordion
          headerText="Islamophobia "
          accordionID="collapse07"
        >
          <p>Islamophobia is defined as: unfounded or disproportionate fear and/or hatred of Islam or Muslims (or people perceived to be Muslim), leading to violence and systemic discrimination. In the Submission to the UN Special Rapporteur on Freedom of Religion or Belief, the report also stated that “Islamophobia intersects with other systems of racism. Asian Muslims, Black Muslims, Indigenous Muslims, and Black Indigenous Muslims experience the compounding effects of anti-Black racism, anti-Indigenous racism, and Islamophobia.” </p>
          <p>There is also evidence that violent incidents targeting the Muslim population were more likely than other types of hate crimes to involve female victims. From 2010 to 2019, almost half (47%) of victims of violent hate crimes targeting the Muslim population were female, compared with one-third (32%) of all hate crime victims. </p>
          <p>Anti-Muslim hate crimes have been on the rise and have often been linked to xenophobia, the stereotype that Muslims pose as a security threat, and general hostility towards Islam. University campuses are no exception to this: </p>
          <div class="panel">
            <ul>
              <li class="pb-2"><em>Toronto, Ontario May 2, 2019. A visibly Muslim woman was asked to follow a proctor into another room for an examination prior to taking her final exam on Thursday at Centennial College. The proctor told her to go somewhere hidden and take her scarf off so they could “check” her. Feeling intimidated, she complied and lifted her veil so the proctor could inspect both the front and back of her head, her ears and her hair.</em> </li>
              <li class="pb-2"><em>Kitchener, Ontario April 21, 2019. A group of 6 visibly Muslim women were outside the Wilfried Laurier University library when a woman approached them. The woman was saying that what they chose to wear does not make them look Canadian. She asked, why they didn’t wear the “sexy and cool clothes the white girls are wearing nowadays to be more Canadian”.</em> </li>
              <li><em>Toronto, Ontario March 22, 2019. A poster outside a multi-faith prayer room was vandalized with the words “A rapist and pedophile” and “Barbarism” at Humber College.</em> </li>
            </ul>
          </div>
          <p class="pt-3">These scenarios reiterate the continuous belief that Islam is “incompatible with Canadian culture”, that it’s perceived as an “odd/barbaric” religion, and that Muslims should be feared. These biases cause many students to feel unsafe, unwelcomed, or self-conscious on campus and put pressure to “prove others wrong” about negative stereotypes of Islam. </p>
          <p>In concluding this pillar, we would be remiss if we did not honour the memory of the Afzaal family by returning our collective consciousness to this recent tragedy. On June 6th, 2021, the Afzaal family was out for an evening walk when a 20-year-old man deliberately drove his truck into the family of five; killing Salman Afzaal, 46, Madiha Salman, 44, Yumna Afzaal, 15, and Talat Afzaal, 74. The family was targeted because of their Muslim identity. This attack is a painful reminder that Islamophobia is not a foreign problem. That day—and this tragedy—happened in our province. It should give us pause to think about what we value in our community and what we wish to be true of that community. </p>
          <p>As we engage and learn about several identities, we may also act as catalysts (or conduits) for equity, diversity and inclusion in our local spaces—be it a classroom, a neighbourhood or even a restaurant downtown. The ways in which we engage with each other truly makes a difference in helping families like the Afzal’s feel safer, welcome and a part of our shared space. </p>
        </accordion>
        <accordion
          headerText="Glossary "
          accordionID="collapse08"
        >
          <p>A hijab differs from a burka and niqab. See below for definitions of burka and niqab. </p>
          <p>The burka is the most concealing of all Islamic veils. It’s a one-piece veil that covers the face and body, often leaving just a mesh screen to see through. </p>
          <p>The niqab is a veil for the face that leaves the area around the eyes clear. However, it may be worn with a separate eye veil. It is worn with an accompanying headscarf. </p>
        </accordion>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
